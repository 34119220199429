import { useFrame } from '@react-three/fiber';
import React, { useRef } from 'react'
import * as THREE from 'three'; 

export default function Mars() {
  const loader = new THREE.TextureLoader();
  const mars_texture = loader.load("/mars_uv.jpg");

  const marsRef = useRef<THREE.Mesh>(null!);

  useFrame((_, t) => {
      marsRef.current.rotation.y += 0.12 * t;
  });

  return (
    <>
      <mesh ref={marsRef}>
        <ambientLight intensity={0.4} />
        <sphereGeometry args={[0.8, 50, 50]} />
        <meshLambertMaterial map={mars_texture} />
      </mesh>
    </>
  );
}