import React, { useContext, useState } from 'react'
import { INavBarItem, INavBarProps, IThemeSwitcherProps, Theme } from '../../resources/Interfaces';
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import ThemeSwitcher from './ThemeSwitcher';
import { ThemeContext } from '../../resources/Contexts';

export default function Navbar(props: INavBarProps) {

  const drawerWidth: number = 240;  // pixels

  const titleText: string = 'Jacob C';

  const navItems: INavBarItem[] = [
    {
      title: 'Home',
      linkPath: '/'
    },
    {
      title: 'About',
      linkPath: '/about'
    },
    {
      title: 'Orbit',
      linkPath: '/orbit'
    }
  ];

  const [mobileOpen, setMobileOpen] = useState<boolean>(false);

  let theme: Theme = useContext<Theme>(ThemeContext);
  
  function handleDrawerToggle(): void {
    setMobileOpen((prevState) => !prevState);
  };

  let themeSwitcherProps: IThemeSwitcherProps = {...props}
  
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <NavLink id='nav-item' to={item.linkPath} className={ theme === Theme.Dark ? "nav-item-dark" : "nav-item-light"}>
              <Typography>{item.title}</Typography>
            </NavLink>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {titleText}
          </Typography>
          <ThemeSwitcher {...themeSwitcherProps} />
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              <NavLink id='nav-item' className={theme === Theme.Dark ? "nav-item-dark" : "nav-item-light"} key={item.title} to={item.linkPath}>
                {item.title}
              </NavLink>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }} 
          sx={{ display: { xs: 'block', sm: 'none' },'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },}}>
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}
