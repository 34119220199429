import { useEffect, useState } from "react";
import { IWindowSize } from "../resources/Interfaces";
export default function useWindowSize(): IWindowSize {

  const [windowSize, setWindowSize] = useState<IWindowSize>({innerHeight: window.innerHeight, innerWidth: window.innerWidth});

  function windowSizeHandler(): void {
    setWindowSize({innerHeight: window.innerHeight, innerWidth: window.innerWidth});
  }

  useEffect(() => {
    window.addEventListener("resize", windowSizeHandler);
    return () => {
      window.removeEventListener("resize", windowSizeHandler);
    };
  }, []);

  return windowSize;
};
