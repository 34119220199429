import { Card, Stack, Switch } from '@mui/material'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import React, { useContext } from 'react'
import { ThemeContext } from '../../resources/Contexts';
import { IThemeSwitcherProps, Theme } from '../../resources/Interfaces';

export default function ThemeSwitcher(props: IThemeSwitcherProps) {

  let themeMode: Theme = useContext<Theme>(ThemeContext)

  return (
    <Card sx={{borderRadius: '10px'}}>
      <Stack direction='row' alignItems='center' sx={{paddingLeft: '8px'}}>
        {themeMode === Theme.Dark ? <DarkModeIcon /> : <LightModeIcon />}
        <Switch onChange={() => themeMode === Theme.Dark ? props.setTheme(Theme.Light) : props.setTheme(Theme.Dark)} checked={themeMode === Theme.Dark}/>
      </Stack>
    </Card>
  )
}
