import React from 'react'
import Navbar from '../components/navbar/Navbar'
import { Outlet } from 'react-router-dom'
import BackgroundContainer from '../components/3d/BackgroundContainer'
import { INavBarProps, IPageLayoutProps } from '../resources/Interfaces'

export default function PageLayout(props: IPageLayoutProps) {

  let navbarProps: INavBarProps = {...props};

  return (
    <div id='page-container'>
      <Navbar {...navbarProps} />
      <BackgroundContainer />
      <div id='outlet-container' className="no-click">
        <Outlet />
      </div>
    </div>
  )
}
