import { useFrame } from '@react-three/fiber';
import React, { useContext, useRef } from 'react'
import * as THREE from 'three';
import { ThemeContext } from '../../../resources/Contexts';
import { Theme } from '../../../resources/Interfaces';

export default function Skybox() {

  let theme: Theme = useContext<Theme>(ThemeContext);

  const loader = new THREE.TextureLoader();
  loader.setPath('/skybox/');

  let skyboxPaths: {light: string, dark: string} = {light: "faded-background.jpg", dark: "skybox_right1.png"} 

  const skyboxRef = useRef<THREE.Mesh>(null!);
  const pointsRef = useRef<THREE.Points>(null!);

  const starCount: number = 120;
  const totalStarPoints: number = starCount * 3;
  let starVertices = new Float32Array(totalStarPoints);
  const scale: number = 14;

  for (let i = 0; i < totalStarPoints; i += 3) {
    for (let j = 0; j < 3; j++) {
      let coord: number = Math.random() - 0.5;
      starVertices[i+j] = coord;
    }
    let vector = new THREE.Vector3(starVertices[i], starVertices[i+1], starVertices[i+2]);
    vector.clampLength(0.3,1).multiplyScalar(scale).toArray(starVertices, i);
  }

  useFrame((_, t) => {
    skyboxRef.current.rotation.x += 0.005 * t;
    skyboxRef.current.rotation.y += 0.01 * t;

    pointsRef.current.rotation.x += 0.01 * t;
    pointsRef.current.rotation.y += 0.02 * t;
  });

  return (
    <>
    <mesh ref={skyboxRef}>
      <sphereGeometry args={[10,50,50]} />
      <meshLambertMaterial side={THREE.BackSide} map={theme === Theme.Dark ? loader.load(skyboxPaths.dark): loader.load(skyboxPaths.light)} />
    </mesh>
    <points ref={pointsRef}>
      <bufferGeometry>
        <bufferAttribute attach='attributes-position' count={starCount} itemSize={3} array={starVertices} />
      </bufferGeometry>
      <pointsMaterial color={0xffffff} size={0.08} />
    </points>
    <pointLight position={[8, 0, 0]} intensity={70} color={0xFFE8D4}/>
    </>
  );
}
