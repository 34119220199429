import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import PageLayout from './pages/PageLayout';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import { CssBaseline, ThemeProvider } from '@mui/material';
import OrbitPage from './pages/OrbitPage';
import { SceneContext, ThemeContext } from './resources/Contexts';
import { darkTheme, lightTheme } from './theme';
import { IPageLayoutProps, IScene, Theme } from './resources/Interfaces';
import { sceneList } from './resources/StaticContent';

function App() {

  let [theme, setTheme] = useState<Theme>(Theme.Dark);

  let pageLayoutProps: IPageLayoutProps = {
    setTheme: setTheme
  }

  const [currentScene, setCurrentScene] = useState<IScene>(sceneList[0]); // The FIRST scene in the list is the DEFAULT
  
  return (
    <div className="App">
      <CssBaseline />
      <SceneContext.Provider value={currentScene}>
      <ThemeContext.Provider value={theme}>
      <ThemeProvider theme={theme === Theme.Dark ? darkTheme : lightTheme}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<PageLayout {...pageLayoutProps} />}>
              <Route index element={<HomePage />} />
              <Route path='/about' element={<AboutPage />} />
              <Route path='/orbit' element={<OrbitPage {...{setScene: setCurrentScene}} />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
      </ThemeContext.Provider>
      </SceneContext.Provider>
    </div>
  );
}

export default App;
