import React from 'react'
import Earth from '../objects/Earth'

export default function Default() {

  return (
    <>
      <ambientLight intensity={0.2} />
      <Earth />
    </>
  )
}

