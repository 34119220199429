import { Container, Stack, Typography } from '@mui/material'
import React, { useContext } from 'react'
import { ThemeContext } from '../resources/Contexts'
import { Theme } from '../resources/Interfaces';

export default function HomePage() {

  let theme: Theme = useContext<Theme>(ThemeContext);

  return (
    <Container sx={
      {
        pt: '12px',
        width: {
          xs: '100%',
          sm: '90%',
          md: '90%',
          lg: '70%',
          xl: '70%'
        }
      }
    }>
      <Stack direction="column" alignItems="center" sx={{color: theme === Theme.Dark ? 'white': 'black'}}>
        <Typography sx={{fontSize:{xs:'60px', md:'112px'}}} className="slide-in-top">Hello there.</Typography>
        <Typography variant="h5" className="slide-in-top-delayed">Welcome.</Typography>
      </Stack>
    </Container>
  )
}
