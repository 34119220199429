// Shared Interfaces
import { FunctionComponent } from "react";

export interface IWindowSize {
  innerWidth: number;
  innerHeight: number;
}
export interface IPageLayoutProps {
  setTheme: Function;
}

export interface INavBarProps {
  setTheme: Function;
}

export interface INavBarItem {
  title: string;
  linkPath: string;
}

export interface IThemeSwitcherProps {
  setTheme: Function;
}

export interface IProjectItem {
  title: string;
  info: string;
  skills: Skills[];
  image: string[];
  imageDescription: string[];
}

export interface ISkillsList {
  technologies: ITechItem[];
  fields: IFieldItem[];
}

export interface ITechItem {
  label: string;
  image: string;
}

export interface IFieldItem {
  name: string;
  image: string;
}

export interface ISkillBadge {
  skill: Skills;
  color: string;
  text: string;
}
export enum Skills {
  MATLAB,
  Csharp,
  Ts,
  Python,
  CFD,
  React,
  AI,
  Angular,
}

export enum OrbitObjectType {
  Planet,
  Satellite,
  Mission
}

export enum Theme {
  Dark = "dark",
  Light = "light"
}

export enum SceneEnum {
  Default,
  Mars,
  Saturn,
  Play
}
export interface IScene {
  name: string;
  Id: SceneEnum;
  component: FunctionComponent;
}

export interface IOrbitPageProps {
  setScene: Function;
}

export interface IProjectDialogProps {
  imgSrc: string;
  projectDescription: string;
}

export interface IOrbitInfo {
  diameter: number;
  inclination: number;
}
export interface IOrbitObject {
  title: string;
  type: OrbitObjectType;
  orbitInfo: IOrbitInfo;
}

export interface objectFormItem {
  title: string;
  checked: boolean;
}

export interface IPlaySceneProps {
  applyScale: boolean;
  objectControls: objectFormItem[];
}