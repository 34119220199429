import Default from "../components/3d/scenes/Default";
import Mars from "../components/3d/scenes/Mars";
import Play from "../components/3d/scenes/Play";
import Saturn from "../components/3d/scenes/Saturn";
import { IOrbitObject, IProjectItem, IScene, ISkillBadge, ISkillsList, OrbitObjectType, SceneEnum, Skills } from "./Interfaces";

export const skillsList: ISkillsList = {
  technologies: [
    {
      label: "Agile Software Development",
      image: ""
    },
    {
      label: "Modern Web Development",
      image: ""
    }
  ],
  fields: [
    {
      name: "Orbital Dynamics",
      image: ""
    },
    {
      name: "Flight Mechanics",
      image: ""
    }
  ]
};

export const skillsBadgeList: ISkillBadge[] = [
  {
    skill: Skills.MATLAB,
    text: "MATLAB",
    color: "orange"
  },
  {
    skill: Skills.Csharp,
    text: "C#",
    color: "purple"
  },
  {
    skill: Skills.Ts,
    text: "TS",
    color: "#1777ec"
  },
  {
    skill: Skills.Python,
    text: "Py",
    color: "yellow"
  },
  {
    skill: Skills.CFD,
    text: "CFD",
    color: "#abc111"
  },
  {
    skill: Skills.AI,
    text: "AI",
    color: "pink"
  },
  {
    skill: Skills.React,
    text: "React",
    color: "blue"
  },
  {
    skill: Skills.Angular,
    text: "Angular",
    color: "red"
  }
];

export const projectItemsList: IProjectItem[] = [
  {
    title: "Space Debris Mitigation",
    image: ["i57r90.png"],
    skills: [Skills.MATLAB],
    info: "Passive removal of space debris by induced orbital instability study for the current MEO region for a bachelors project.",
    imageDescription: ["Feasible passive disposal routes for space debris in the MEO region are shown in the colour of the squares as the number of years until disposal. Found using orbital propagation, built in MATLAB."]
  },
  {
    title: "Hydrogen Safe Distance Estimation Model - MEng Project (Elomatic Oy)",
    image: ["CFD-pretty.png", "ml_bigplot_important.png"],
    skills: [Skills.AI, Skills.CFD, Skills.Python],
    info: `A machine learning based prototype hydrogen leakage-safe distance estimation tool, built as a masters project
    in partnership with Elomatic Oy.
    `,
    imageDescription: ["A CFD simulation of a hydrogen leakage scenario, indicating hydrogen cloud size over time, and providing meaningful estimate values to predict safe distance from.",
      "Safe distance - Overpressure predictions from our model, alongside extreme probability results from a Monte-Carlo simulation."]
  },
];

export const sceneList: IScene[] = [
  { // The FIRST item here is the DEFAULT!
    name: "Home",
    Id: SceneEnum.Default,
    component: Default
  },
  {
    name: "Mars",
    Id: SceneEnum.Mars,
    component: Mars
  },
  {
    name: "Saturn",
    Id: SceneEnum.Saturn,
    component: Saturn
  },
  {
    name: "Play",
    Id: SceneEnum.Play,
    component: Play
  }
]

export const orbitObjectList: IOrbitObject[] = [
  {
    title: "ISS",
    type: OrbitObjectType.Satellite,
    orbitInfo: {
      diameter: 10,
      inclination: 45
    }
  },
  {
    title: "GNSS",
    type: OrbitObjectType.Satellite,
    orbitInfo: {
      diameter: 12,
      inclination: 45
    }
  }
]