import { Typography } from '@mui/material'
import React from 'react'
import { IProjectDialogProps } from '../resources/Interfaces'

export default function ProjectDialog(props: IProjectDialogProps) {
  return (
    <>
      <img src={props.imgSrc} alt='project' width='100%'/>
      <Typography>
        {props.projectDescription}
      </Typography>
    </>
  )
}
