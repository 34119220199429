import { useFrame } from '@react-three/fiber';
import React, { useRef } from 'react'
import * as THREE from 'three'; 
import rings_vertex_shader from '../shaders/rings/rings_vertex.glsl';
import rings_fragment_shader from '../shaders/rings/rings_fragment.glsl';

export default function Saturn() {
  let loader = new THREE.TextureLoader();
  const saturn_texture = loader.load("/saturn_uv.jpg");
  const ring_texture = loader.load("/saturn-rings-top.png");;

  const ringInnerRadius: number = 1;
  const ringOuterRadius: number = 1.4;

  const uniforms =  {
    'rings': { value: ring_texture },
    'innerRadius': { value: ringInnerRadius },
    'outerRadius': { value: ringOuterRadius }
  }

  const saturnRef = useRef<THREE.Mesh>(null!);
  const ringRef = useRef<THREE.Mesh>(null!);
  const moonsRef = useRef<THREE.Mesh>(null!);

  useFrame((_, t) => {
    saturnRef.current.rotation.y += 0.12 * t;  
    ringRef.current.rotation.z += 0.13 * t;
  });

  return (
    <>
      <mesh ref={saturnRef} rotation={[0, 0, Math.PI/48]}>
        <ambientLight intensity={0.4} />
        <sphereGeometry args={[0.8, 50, 50]} />
        <meshLambertMaterial map={saturn_texture} />
      </mesh>
      <mesh ref={ringRef} rotation={[Math.PI/2, Math.PI/48, 0]}>
        <ambientLight intensity={1} />
        <ringGeometry args={[ringInnerRadius, ringOuterRadius, 64]} />
        <shaderMaterial vertexShader={rings_vertex_shader} fragmentShader={rings_fragment_shader} uniforms={uniforms} transparent={true} side={THREE.DoubleSide} />
      </mesh>
      <mesh ref={moonsRef}>
      </mesh>
    </>
  );
}