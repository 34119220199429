import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      light: "#1C5D90",
      main: "#48B350",
      contrastText: "#FFF"
    },
    secondary: {
      light: "#86CAFF",
      main: "#2f93e0",
      contrastText: "#FFF"
    },
    error: {
      main: "#db4437"
    },
    warning: {
      main: "#fb8c00"
    },
    info: {
      main: "#0878D1"
    },
    success: {
      main: "#48B350"
    },
    text: {
      // The most important text.
      primary: "#E3EEF6",
      // Secondary text.
      secondary: "#8B9EAC",
      // Disabled text have even lower visual prominence.
      disabled: "#9E9E9E"
    },
    // The color used to divide different elements.
    divider: "#6D7D87",
    // The background colors used to style the surfaces.
    // Consistency between these values is important.
    background: {
      paper: "#112E44",
      default: "#0F2535"
    },
    // The colors used to style the action elements.
    action: {
      // The color of an active action like an icon button.
      active: "rgba(0, 0, 0, 0.54)",
      // The color of an hovered action.
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      // The color of a selected action.
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      // The color of a disabled action.
      disabled: "rgba(0, 0, 0, 0.26)",
      // The background color of a disabled action.
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  }
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      light: "#1C5D90",
      main: "#2f93e0",
      dark: "#0F2535",
      contrastText: "#FFF"
    },
    secondary: {
      main: "#2f93e0",
      contrastText: "#FFF"
    },
    error: {
      main: "#db4437"
    },
    warning: {
      main: "#fb8c00"
    },
    info: {
      main: "#0878D1"
    },
    success: {
      main: "#48B350"
    },
    text: {
      // The most important text.
      primary: "#333",
      // Secondary text.
      secondary: "#E0E0E0",
      // Disabled text have even lower visual prominence.
      disabled: "#9E9E9E",
    },
    // The color used to divide different elements.
    divider: "rgba(0, 0, 0, 0.12)",
    // The background colors used to style the surfaces.
    // Consistency between these values is important.
    background: {
      paper: "#FFF",
      default: "#FAFAFA"
    },
    // The colors used to style the action elements.
    action: {
      // The color of an active action like an icon button.
      active: "rgba(0, 0, 0, 0.54)",
      // The color of an hovered action.
      hover: "rgba(0, 0, 0, 0.04)",
      hoverOpacity: 0.04,
      // The color of a selected action.
      selected: "rgba(0, 0, 0, 0.08)",
      selectedOpacity: 0.08,
      // The color of a disabled action.
      disabled: "rgba(0, 0, 0, 0.26)",
      // The background color of a disabled action.
      disabledBackground: "rgba(0, 0, 0, 0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12
    }
  }
});

export const dark = { ...darkTheme };
export const light = { ...lightTheme };