import { Box, Button, Card, CardContent, Checkbox, Grid2, Stack, Typography } from '@mui/material'
import React, { useContext, useState } from 'react'
import { ThemeContext } from '../resources/Contexts';
import { IOrbitPageProps, IScene, objectFormItem, SceneEnum, Theme } from '../resources/Interfaces';
import { orbitObjectList, sceneList } from '../resources/StaticContent';

export default function OrbitPage(props: IOrbitPageProps) {

  let theme: Theme = useContext<Theme>(ThemeContext);

  let scenes: IScene[] = sceneList;

  //REMOVE PLAY BUTTON FOR PRODUCTION
  scenes = scenes.filter(scene => scene.Id !== SceneEnum.Play);

  function onSceneClick(sceneId: SceneEnum): void {
    const sceneIndex: number = scenes.findIndex(scene => scene.Id === sceneId);
    if (sceneIndex > -1) {
      props.setScene(scenes[sceneIndex]);
      setIsPlayScene(scenes[sceneIndex].Id === SceneEnum.Play);
    }
  }

  const [isPlayScene, setIsPlayScene] = useState<boolean>(false);

  const orbitObjectFormList: objectFormItem[] = orbitObjectList.map(object => {return{title: object.title, checked: false}})

  const [orbitObjectControl, setOrbitObjectControl] = useState<objectFormItem[]>(orbitObjectFormList);

  function onOrbitControlChange(objectTitle: string): void {
    const controls = orbitObjectControl;
    const objectIndex = controls.findIndex(orbit => orbit.title === objectTitle);
    if (objectIndex > -1) {
      controls[objectIndex].checked = !controls[objectIndex].checked
      setOrbitObjectControl([...controls]);
    }
  }

  function ObjectControls(): React.ReactElement {
    return(
      <Card >
        <CardContent>
          <Typography sx={{textAlign:"center"}}>Objects</Typography>
          <Stack direction="column" alignItems={"end"}>
            {
              orbitObjectControl.map((object) => (
                <Stack direction="row" justifyContent={"end"} alignItems={"center"}>
                  <Typography sx={{marginTop:"1px"}}>{object.title}</Typography>
                  <Checkbox key={object.title} checked={object.checked} onChange={() => onOrbitControlChange(object.title)} />
                </Stack>
              ))
            }
          </Stack>
        </CardContent>
      </Card>
    )
  }
  
  return (
    <>
      <Typography justifySelf='center' variant='h5' sx={{color: theme === Theme.Dark ? 'white': 'black'}}>Orbit</Typography>
      <Box>
      <Grid2 container sx={{
        pt: '12px',
        width: '150px',
        justify: "start",
        paddingLeft: '24px',
        gap: '20px'
      }}>
        <Card>
          <CardContent>
            <Stack direction="column">
            <Typography sx={{textAlign:"center"}}>Scenes</Typography>
            {
              scenes.map((scene) => (
                <Button key={scene.Id} onClick={()=>onSceneClick(scene.Id)}>{scene.name}</Button>
              ))
            }
            </Stack>
          </CardContent>
        </Card>
        {isPlayScene ? <ObjectControls /> : <></>}
      </Grid2>
      </Box>
    </>
  )
}
