import { Box, Card, Container, Dialog, DialogContent, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { projectItemsList } from '../resources/StaticContent';
import ProjectDialog from '../components/ProjectDialog';
import { IProjectDialogProps } from '../resources/Interfaces';

export default function AboutPage() {

  const [dialogState, setDialogState] = React.useState<IProjectDialogProps>({imgSrc: "", projectDescription: ""});

  const aboutImageSrc: string = './portrait.png';

  const aboutText: string =
  `
  I'm a software engineer from Scotland.
  `;

  return (
    <>
      <Container sx={{paddingTop: '20px', maxWidth:{xs:'90%', md:'75%', lg:'60%'}}}>
        <Stack direction="column" gap="10px" alignItems={'center'}>
          <Stack direction="row" sx={{alignItems: 'center', justifyContent:'center', position:'relative', right:{xs:'0px', sm:'12px', gap:{xs:'12px', md:'0px'}}}}>
            <Card sx={{display: 'flex', maxHeight:{xs:'180px',sm:'120px'}, position: 'relative', left:{xs:'22px',sm:'24px'}, boxShadow:'none', zIndex:-1, borderRadius:'16px 0px 0px 16px', paddingRight:{xs:'2px',sm:'12px'}}}>
              <Stack padding={'8px'}>
                <Typography fontSize={'1.4em'}>
                  About Me
                </Typography>
                <Typography sx={{marginRight:'10px', fontSize:{xs:'0.9em', md:'1em'}}}>
                  {aboutText}
                </Typography>
              </Stack>
            </Card>
            <Card sx={{minWidth:{xs:'175px',md:'240px'}, minHeight:{xs:'175px',md:'240px'}, borderRadius:'130px', display:'flex', justifyContent:'center', alignItems:'center', boxShadow:'none'}}>
              <Box component='img' src={aboutImageSrc} alt='Me'  sx={{width:{xs:'160px',md:'220px'}, height:{xs:'160px',md:'220px'}, borderRadius: '122px', border: '6px solid #404040'}} />
            </Card>
          </Stack>
          <Card sx={{padding:'4px 10px 4px 10px'}}>
            <Typography fontSize={'1.3em'} justifySelf={'center'}>My Projects</Typography>
          </Card>
          {projectItemsList.map((project, i) => (
          <Stack alignItems={'center'} gap={'20px'} sx={{paddingBottom:'20px', direction:{xs:'column', md:'row'}}}>
            <Card key={project.title} sx={{padding:'12px'}}>
              <Typography fontSize={'1.2em'}>{project.title}</Typography>
              <Typography>{project.info}</Typography>
            </Card>
            <Stack direction={{xs:'column', md:'row'}} gap={'20px'} sx={{justifyContent:'center', alignItems:'center'}}>
            {project.image.map((imgSrc, i) =>(
            <Tooltip title="Click Me!">
              <Card key={project.title+imgSrc} sx={{padding:'10px', borderRadius:'10px'}} onClick={() => setDialogState({imgSrc: imgSrc, projectDescription: project.imageDescription[i]})} className='hover-animate'>
                <img src={imgSrc} alt='project' width={'300px'} height={'300px'} style={{borderRadius:'10px'}} />
              </Card>
            </Tooltip>
            ))}
            </Stack>
          </Stack>
          ))}
        </Stack>
      </Container>
      <Dialog sx={{zIndex:1000}} maxWidth={'lg'} open={dialogState.imgSrc !== ""} onClose={() => setDialogState({imgSrc: "", projectDescription: ""})}>
        <DialogContent>
          <ProjectDialog {...dialogState}/>
        </DialogContent>
      </Dialog>
    </>
  )
}