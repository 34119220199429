import { OrbitControls, PerspectiveCamera } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import * as THREE from 'three';
import { IScene, IWindowSize } from '../../resources/Interfaces';
import useWindowSize from '../../hooks/useWindowSize';
import { useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Default from './scenes/Default';
import { SceneContext } from '../../resources/Contexts';
import Skybox from './objects/Skybox';

export default function BackgroundContainer() {

  let cameraRef = useRef<THREE.PerspectiveCamera>(null!);

  let startPosition = new THREE.Vector3(0,0,5);

  let windowSize: IWindowSize = useWindowSize();

  let location = useLocation();

 let currentScene: IScene = useContext(SceneContext);

  return (
    <div id="canvas-container">
      <Canvas>
        {
          location.pathname.includes('/orbit') ? 
          <>
            <OrbitControls />
            <currentScene.component />
          </>
          : 
          <>
            <PerspectiveCamera makeDefault ref={cameraRef} position={startPosition} zoom={Math.pow(windowSize.innerWidth/windowSize.innerHeight, 0.5)} />
            <Default />
          </>
        }
        <Skybox />
      </Canvas>
    </div>
  )
}
