import { useFrame } from '@react-three/fiber';
import React, {  useRef } from 'react'
import * as THREE from 'three'; 
import earth_vertex_shader from '../shaders/earth/earth_vertex.glsl';
import earth_fragment_shader from '../shaders/earth/earth_fragment.glsl';
//import atmos_vertex_shader from '../shaders/atmosphere/atmos_vertex.glsl';
//import atmos_fragment_shader from '../shaders/atmosphere/atmos_fragment.glsl';

export default function Earth() {
  const loader = new THREE.TextureLoader();

  const earth_texture = loader.load("/earth_uv.jpg");
  const moon_texture = loader.load("/moon_uv.jpg");

  const uniforms = { 'earth': { value: earth_texture } };

  const moonSpeed: number = 30;
  let angle: number = 0;

  const earthRef = useRef<THREE.Mesh>(null!);
  //const atmosphereRef = useRef<THREE.Mesh>(null!);
  const moonRef = useRef<THREE.Mesh>(null!)

  useFrame((_, t) => {
      earthRef.current.rotation.y += 0.1 * t;
      //atmosphereRef.current.rotation.y += 0.1 * t;

      angle += t * moonSpeed;
      if (angle > 360) angle = 0;

      moonRef.current.position.x = 1.3*(Math.cos(THREE.MathUtils.degToRad(angle)));
      moonRef.current.position.z = 1.3*(Math.sin(THREE.MathUtils.degToRad(angle)));
      moonRef.current.rotation.y += 1.2 * t;
  });

  return (
    <>
      <mesh ref={earthRef}>
        <sphereGeometry args={[1, 50, 50]} />
        <shaderMaterial vertexShader={earth_vertex_shader} fragmentShader={earth_fragment_shader} uniforms={uniforms} />
      </mesh>
      {/**<mesh ref={atmosphereRef}>
        <sphereGeometry args={[1.15, 50, 50]} />
        <shaderMaterial vertexShader={atmos_vertex_shader} fragmentShader={atmos_fragment_shader} blending={THREE.AdditiveBlending} side={THREE.BackSide} />
      </mesh>*/}
      <mesh ref={moonRef} position={[1.5, 0, 0]}>
      <sphereGeometry args={[0.14, 50, 50]} />
      <meshLambertMaterial map={moon_texture}/>
      </mesh>
    </>
      
  );
}
