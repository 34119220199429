import React from 'react'
import Earth from '../objects/Earth'

export default function Play() {
  return (
    <>
      <ambientLight intensity={0.2} />
      <Earth />
    </>
  )
}

